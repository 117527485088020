import { hashconnect, initHashconnect } from './initHashconnect.js'


export default async () => {

    const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	
    const api_auth = './vendor/hashconnect/tx_auth.php'

    const data = await fetch(api_auth + '?send')
    //const data = await fetch('https://dev4.shop.gomint.me/vendor/hashconnect/tx_auth.php?send')

    const resData = await data.json()
    console.log('res data', resData)
    const signingData = resData.signingData
    // Convert object to array to buffer
    const serverSigAsArr = Object.values(signingData.serverSignature)
    const serverSigAsBuffer = Buffer.from(serverSigAsArr)


    // initialise hashconnect obj and get returned data
    const initData = await initHashconnect(hashconnect)

    const url = window.location.hostname
	console.log('url = window.location.hostname = ' + url)
    //const url = "labs4.shop.gomint.me"
    const payload = {
        url,
        data: {
            token: '121'  // this must match the token on the back-end also
        }
    }
		
    const auth = await hashconnect.authenticate(
        initData.topic,
        hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
        signingData.serverSigningAccount,
        serverSigAsBuffer,
        payload
    )

    console.log('auth = %o ', auth)
	
    const receiveAuthData = {
        signingAccount: hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
        auth
    }
	
	console.log('receiveAuthData = %o ',  receiveAuthData)


    const res = await fetch(api_auth + '?receive', {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(receiveAuthData),
    })


	const { authMessage } = await res.json()
	console.log('authMessage json %o ',  authMessage)
	
	window.location.reload();

}