import { HashConnect } from 'hashconnect'
import { getConfig } from './utils'

export let hashconnectSessionData = {
    pairedWallets: [],
    activeWalletIndex: undefined
}

export const hashconnect = new HashConnect()

export const initHashconnect = async (hashconnect) => {
    // get json config file
	const config = await getConfig()

    try {
        // initialize hashconnect object
		// todo .. refactor and get network from external function ref GJ87765 see below
		const urlParams = new URLSearchParams(window.location.search)
		const network = document.getElementById('network')?.value ?? urlParams.get('network') ?? config.network
		
		//if (network != config.network) window.alert(`Network has been set to ${network}`)
		if (network != config.network) {
			console.log("initHashconnect.js")
			console.log(`network = ${network}`)
			console.log(`config.network = ${config.network}`)
			console.log("html id network = " + document.getElementById('network')?.value)
			console.log("url network = " + urlParams.get('network'))
			window.alert(`Network has been set to ${network}`)
		}			
		
        const initData = await hashconnect.init(config.appMetadata, network, false)
        return initData
    } catch (err) {
        window.open('https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk', '_blank').focus();
        console.error(err)
    }

}
