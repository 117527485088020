import { hashconnect, initHashconnect, hashconnectSessionData } from './initHashconnect.js'
import { Transaction } from '@hashgraph/sdk';

export default async (bladeSigner) => {
console.log("start  tx_sign.js")
// ┌─┐┌─┐┌┬┐┬ ┬┌─┐┬─┐  ┌─┐┌─┐┬─┐┌─┐┌┬┐┌─┐┌┬┐┌─┐┬─┐┌─┐
// │ ┬├─┤ │ ├─┤├┤ ├┬┘  ├─┘├─┤├┬┘├─┤│││├┤  │ ├┤ ├┬┘└─┐
// └─┘┴ ┴ ┴ ┴ ┴└─┘┴└─  ┴  ┴ ┴┴└─┴ ┴┴ ┴└─┘ ┴ └─┘┴└─└─┘
// gather parameters needed to process the request
// bit scattered, could refactor later

	// get network from file config.json
	const config = await (await fetch('./vendor/hashconnect/config.json')).json()
	// todo .. refactor and get network from external function ref GJ87765 see below
	const urlParams = new URLSearchParams(window.location.search);	
	const network = urlParams.get('network') ?? config.network

	if (network != config.network) window.alert(`Network has been set to ${network}`)

	
	// get txid from URL	
	let txid = null
	if (urlParams.has('txid')) txid = urlParams.get('txid')
		else console.log('missing txid')

	// get userAccountId from session storage 
    const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	//let userAccountId = hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]
	let userAccountId = hashconnectSessionData
		? hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]
		: bladeSigner.getActiveAccountId()

	// check for any form data to attach
	const user_notes = document.getElementById("user_notes");
	//if (!user_notes) ....

    const data = {
        network: network,
        userAccountId: userAccountId,
		txid: txid,
		user_notes: user_notes
    }

// ┌─┐┌─┐┌┬┐  ┌┬┐─┐ ┬
// │ ┬├┤  │    │ ┌┴┬┘
// └─┘└─┘ ┴    ┴ ┴ └─
// get tx

	// pass network, userAccountId, and dealID to the signer
	const api_sign = './vendor/hashconnect/tx_recall.php'	
    const response = await fetch(api_sign, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })

    //const { bytes } = await response.json()  // get the transaction ready for signing
	const result = await response.json()  // get the transaction ready for signing
	console.log("get bytes result=", result)
	if (result.hasOwnProperty('error')) {
		console.log('error ' + result.error)
	
		let error_message = result.error
		document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-warning' role='alert' id='last_tx_result'>${error_message}</div>`	
		throw new Error();
		
	}

/* yes this script is only for future dated.. but could  reuse some code here for validation
// ┌─┐┬ ┬┌┬┐┬ ┬┬─┐┌─┐  ┌┬┐┌─┐┌┬┐┌─┐┌┬┐┌─┐
// ├┤ │ │ │ │ │├┬┘├┤    ││├─┤ │ ├┤  ││ ┌┘
// └  └─┘ ┴ └─┘┴└─└─┘  ─┴┘┴ ┴ ┴ └─┘─┴┘ o 
// future dated? isScheduled	

	let isScheduled = result.hasOwnProperty('txid')
	if (isScheduled) { 
	
		let txid = result.txid
		let validSeconds = txid.split("@")[1];

		let validDate = new Date( validSeconds *1000);
		let validDateStr = validDate.toGMTString()
		
		//+"<br>"+validDate.toLocaleString());


		let user_message = 'Transaction has been scheduled with ID ' + result.txid + '<br>at ' + validDateStr
		console.log(user_message)
	
		document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-info' role='alert' id='last_tx_result'>${user_message}</div>`	
		//throw new Error(); // probably could return instead
		
	}

*/

// todo create a simple page to set the txid in the url, and call this to execute

	//console.log("result=", result)
	const bytes = result; // i think bytes is at the root
	//const { bytes } = result;
	//console.log(bytes)


let isScheduled = true
	
// ┌─┐┌─┐┌┐┌┌┬┐  ┌┬┐─┐ ┬  ┌┬┐┌─┐  ┬ ┬┌─┐┌─┐┬ ┬┌─┐┌─┐┌─┐┬┌─
// └─┐├┤ │││ ││   │ ┌┴┬┘   │ │ │  ├─┤├─┤└─┐├─┤├─┘├─┤│  ├┴┐
// └─┘└─┘┘└┘─┴┘   ┴ ┴ └─   ┴ └─┘  ┴ ┴┴ ┴└─┘┴ ┴┴  ┴ ┴└─┘┴ ┴
//or send to blade
// same code as in transaction.js.. todo refactor and import

	let sendTxRes
	// send tx to hashpack
	if (hashconnectSessionData) {
		console.log("Paying with hashconnect")
		// initialise hashconnect obj and get returned data
		const initData = await initHashconnect(hashconnect)
			
		const transData = {
			topic: initData.topic,
			byteArray: bytes,
			metadata: {
				accountToSign: hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
				returnTransaction: isScheduled
			}
		}

		// ask the user to sign
		sendTxRes = await hashconnect.sendTransaction(initData.topic, transData)
	} 
	// handle tx with blade
	else {
		console.log("Paying with blade")   // todo, refactor how to organise Hashpack vs Blade
		//const tx = Transaction.fromBytes(new Uint8Array(bytes.data))
		const tx = Transaction.fromBytes(new Uint8Array(bytes))
		if (isScheduled) {	
			console.log("sign only with blade - tbc untested")
			const [signedTransaction, transactionId] = await bladeSigner.signAndReturnBytes(tx).catch(err => console.error("Error returning bytes"))

			sendTxRes = { // roughly match hashconnect response
				//network: network,  // not needed, as server side php has this
				success: true, //? tbc
				transactionId,
				signedTransaction,
				raw: JSON.stringify({transactionId, signedTransaction}) // debug only
			}

		} else { // execute now
			console.log("execute with blade - tested ok")
			const bladeTxRes = await bladeSigner.call(tx).catch(err => {throw new Error(err)})
			console.log(bladeTxRes)

			sendTxRes = {
				success: true, //? tbc
				response: {
					transactionId: bladeTxRes.transactionId.toString()
				}
			}
		}
	}
    
	console.log(sendTxRes)
	
    sendTxRes.success
        ? console.log('Signed successfully')
        : console.log('Error')
	
	

// ┌─┐┌─┐┬  ┬┌─┐  ┬─┐┌─┐┌─┐┌─┐┌─┐┌┐┌┌─┐┌─┐
// └─┐├─┤└┐┌┘├┤   ├┬┘├┤ └─┐├─┘│ ││││└─┐├┤ 
// └─┘┴ ┴ └┘ └─┘  ┴└─└─┘└─┘┴  └─┘┘└┘└─┘└─┘
// save response if scheduled

//hmm we need to update tx_save_result.php to send data back to saas
	// save on the server
	if (isScheduled) {
		let url_save = './vendor/hashconnect/tx_save_result.php'
		const res_save_tx = await fetch(url_save, {

			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sendTxRes),
		})
		
		console.log('result of saving tx...')
		console.log(res_save_tx)
		
		//user_message = 
		// change colour to see it saved
		document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-primary' role='alert' id='last_tx_result'>${user_message}</div>`
		
		throw new Error();
	}

/*	brownout 24-3-2023 not doing anything?
	let last_tx_id = sendTxRes.response.transactionId

	last_tx_id = last_tx_id.replace("@", "-")
	last_tx_id = last_tx_id.replace("@", "-")
	last_tx_id = last_tx_id.replace("0.0.", "bollocks to hashscan")
	last_tx_id = last_tx_id.replace(".", "-")
	last_tx_id = last_tx_id.replace("bollocks to hashscan", "0.0.")


	let url_last_tx = "https://hashscan.io/" + network
	url_last_tx += "/transaction/" + last_tx_id


	// php woudl be $last_tx_id_dg = preg_replace("/[^0-9]/", '', $last_tx_id);
	let last_tx_id_dg = sendTxRes.response.transactionId
	last_tx_id_dg = last_tx_id_dg.replaceAll("-", "")
	last_tx_id_dg = last_tx_id_dg.replaceAll(".", "")
	last_tx_id_dg = last_tx_id_dg.replaceAll("@", "")
	//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replaceAll
	//https://stackoverflow.com/a/1144788/7042126
	
	let url_last_tx_dg = (network == 'mainnet') ? 'https://' : 'https://testnet.'
	url_last_tx_dg += "dragonglass.me/transactions/" + last_tx_id_dg
	//https://testnet.dragonglass.me/transactions/0033712931676279208662089605
	
	
	// todo review.. sendTxRes.success is the result of signing, not necessarily the tx reaching consensus nor even being submitted?!
	let last_tx_alert_type = (sendTxRes.success) ? 'alert alert-success' : 'alert alert-warning'
	let last_tx_result_message = (sendTxRes.success) ? 'Success!' : 'Error:'
	
	document.getElementById('last_tx').innerHTML = `<BR><BR><div class='${last_tx_alert_type}' role='alert' id='last_tx_result'>${last_tx_result_message} Please wait a moment...</div>`

	*/


}
