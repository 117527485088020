export const createOptionForSelect = (value) => {
    const element = document.createElement("option")
    const text = document.createTextNode(value)
    element.appendChild(text)
    return element
}

export const showDisplay = (id) => {
    const element = document.getElementById(id)
    if (element.classList.contains('hide')) {
        document.getElementById(id).classList.remove('hide');
        document.getElementById(id).classList.add('show');
    }

}

export const hideDisplay = (id) => {
    const element = document.getElementById(id)
    if (element.classList.contains('show')) {
        document.getElementById(id).classList.add('hide');
        document.getElementById(id).classList.remove('show');
    }
}

// Asynchronous function to get the config
export async function getConfig() {
    const cachebust = Math.floor(new Date().getTime() / 100000) // Take off milliseconds, plus 2 more to make approx 100 seconds cache
    const pathconfig = './vendor/hashconnect/config.json?t=' + cachebust
    const config = await (await fetch(pathconfig)).json()
    return config
}


/* i have no idea
export const setUnverifiedAccountId = async (accountId) => {
	// send to server (unverified)
	console.log('sending to server ' + accountId)
	const url = 'setUnverifiedAccount.php?unverifiedAccountId=' + accountId
	const response = await fetch(url)
	
	const json = await response.json() 
	console.log('result from server ' + json)
	return json
}

*/

