import { hashconnect, initHashconnect, hashconnectSessionData } from './initHashconnect.js'
// import { HashConnect } from 'hashconnect'

// park... public key update not supported with hashconnect https://discord.com/channels/888550498133766204/913068258503065690/1044361139292737657

export default async () => {

	// get network from file config.json
	const config = await (await fetch('./vendor/hashconnect/config.json')).json()
	// todo .. refactor and get network from external function ref GJ87765
	const urlParams = new URLSearchParams(window.location.search);	
	const network = urlParams.get('network') ?? config.network

	if (network != config.network) window.alert(`Network has been set to ${network}`)

	// get userAccountId from session storage 
    const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	let userAccountId = hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]

	// get user request from URL
	
	let url = './vendor/hashconnect/tx_router.php?'
	url += 'script=account_update_keys'
	url += '&accountId=' + userAccountId
	url += '&newPublicKey=' + urlParams.get('newPublicKey')
	
	const response = await fetch(url)

    const { bytes } = await response.json()  // get the transaction ready for signing

    // initialise hashconnect obj and get returned data
    const initData = await initHashconnect(hashconnect)

    const transData = {
        topic: initData.topic,
        byteArray: bytes,
        metadata: {
            accountToSign: hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
			returnTransaction: false
            //returnTransaction: true  // return the transaction because we also need to sign with the new key
        }
    }

	// ask the user to sign
    const sendTxRes = await hashconnect.sendTransaction(initData.topic, transData)


    sendTxRes.success
        ? console.log('Signed successfully')
        : console.log('Error')
	
	console.log('here is the returned transaction')
	console.log(sendTxRes)
	
	//window.localStorage.setItem('last_tx_result', JSON.stringify(sendTxRes))
	//let last_tx_result = JSON.parse(window.localStorage.getItem('tx_result'))


	// save on the server, in log file and also $_SESSION['tx'][$txid]
	const res_save_tx = await fetch('./vendor/hashconnect/tx_save_result.php?park', {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(sendTxRes),
    })

		
	
	//setTimeout (3000)	
	//window.location.reload();

	//let url_last_tx_DG = `` 
//app.drag... for mainnet
//https://testnet.dragonglass.me/hedera/search?q=d9ce1925353444635c5d8c99beeb518cb7522ca7ff3950fe365e975324453f1e3267f2f4aa1c4dd44c04e456425290c7


}
