
// import { HashConnect } from 'hashconnect'
import BladeSigner from './blade/BladeSigner.js'

import pairHashpack from './pairHashpack.js'
import transaction from './transaction.js'
import tx_sign from './tx_sign.js'

import authenticate from './authenticate.js'
import tx_spender_allowance from './tx_spender_allowance.js'
import tx_account_update from './tx_account_update.js'

import { createOptionForSelect, showDisplay, hideDisplay } from './utils.js'

// Get the current URL
const url = new URL(window.location.href);

// Get the value of the 'productId' parameter
const productId = url.searchParams.get('productId');

let bladeSigner

// Check if 'productId' parameter is missing
if (productId) {
    // Split the 'productId' parameter into three parts using the semicolon (;) as the delimiter
    const [network, token, owner] = productId.split(';')

    console.log(`productId is ${productId}   network is ${network}`)
    bladeSigner = new BladeSigner(network)

} else {
	const network = url.searchParams.get('network') ?? 'testnet'  // fudge for testing
    bladeSigner = new BladeSigner(network)
}

// if (typeof network !== 'undefined') console.dir({network})
// if (typeof productId !== 'undefined') console.dir({productId})
if (typeof bladeSigner !== 'undefined') console.dir({bladeSigner})

let selectAccountId
window.addEventListener('load', (e) => {
    e.preventDefault()
	console.log('load listener triggered')

	if (typeof bladeSigner !== 'undefined') console.log('bladeSigner=', bladeSigner)	

	// if (bladeSigner) {
		// let bladeAccount = bladeSigner.getActiveAccountId()
		// console.log(`bladeAccount = ${bladeAccount}`)
	// } else console.log('bladeSigner is undefined')
	
    let hashconnectSessionData = localStorage.getItem("hashconnectSessionData")

    if (hashconnectSessionData) {
		console.log('hashconnectSessionData=', hashconnectSessionData)
        // show / hide relevant buttons
        hideDisplay('before-pair')
        hideDisplay('pairing')
        showDisplay('paired')
        // fill in text
        hashconnectSessionData = JSON.parse(hashconnectSessionData)
		selectAccountId = document.getElementById('account-id')
        for (let account of hashconnectSessionData.pairedWallets) {
            // add account id to drop down menu
            const element = createOptionForSelect(account)
            selectAccountId.appendChild(element)
        }

        //restore the selected account from session
		selectAccountId.value = selectAccountId[hashconnectSessionData.activeWalletIndex].value
		
        window.localStorage.setItem('hashconnectSessionData', JSON.stringify(hashconnectSessionData))
    } else {
        console.log("No hashpack data")
    }
})


// Blade wallet connection
const pairBladeBtn = document.querySelectorAll('#pair-blade-btn')
pairBladeBtn?.forEach(e => {
    e.addEventListener('click', async () => {
        console.log("Pairing blade wallet")
        try {
			// clear local storage before connecting Blade (as only one connection allowed)
            clearLocalStorage()
            let activeBladeAccount = await bladeSigner.pairBlade()
            console.log("Active blade acct: ", activeBladeAccount)
			
			// populate select element with account id
            const accountIdSelect = document.getElementById('account-id')
            const element = createOptionForSelect(activeBladeAccount)
            accountIdSelect.appendChild(element)
            hideDisplay('before-pair')
            showDisplay('paired')
            console.log("paired")
        } catch (e) {
            console.error("Error pairing blade wallet")
            console.error(e)
		}
        
    })
})


// Handle pair wallet function
const pairBtn = document.querySelectorAll('#pair-btn')
pairBtn?.forEach(e => {
    e.addEventListener('click', async () => {
        const saveData = await pairHashpack()
        const div = document.getElementById('pairing-string')
        div.innerHTML = saveData.pairingString
		// fetch png of QR code
        const url_fetch_png = './vendor/hashconnect/getQR.php?' + saveData.pairingString
        const url_png = (await (await fetch(url_fetch_png)).json()).url_png
        document.getElementById('pairing-png').innerHTML = `<br><img src='${url_png}' width=200>`
    })
})


// Handle change in active account

selectAccountId = document.getElementById('account-id')
selectAccountId?.addEventListener('change', (e) => {
    let hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	
	// update session data active wallet
    hashconnectSessionData.activeWalletIndex = e.target.selectedIndex
	
	// set session data
    window.localStorage.setItem('hashconnectSessionData', JSON.stringify(hashconnectSessionData));

	// copy result to server (not secure, for info only)
    const resultSetUnverifiedAccount = fetch('./vendor/hashconnect/setUnverifiedAccount.php?unverifiedAccountId=' + selectAccountId[hashconnectSessionData.activeWalletIndex].value)
})


const authBtn = document.getElementById('auth-btn')
authBtn?.addEventListener('click', authenticate)


const approveSpendBtn = document.getElementById('approve-spend-btn')
approveSpendBtn?.addEventListener('click', tx_spender_allowance)


// park... not supported with hashconnect https://discord.com/channels/888550498133766204/913068258503065690/1044361139292737657
let accountUpdateBtn
if (document.getElementById('account-update-btn')) {
    accountUpdateBtn = document.getElementById('account-update-btn')
    accountUpdateBtn.addEventListener('click', tx_account_update)
}

// const buyNft = document.getElementById('buy-nft')
// buyNft?.addEventListener('click', () => transaction(bladeSigner))

// more generic - create any tx from a deal template
const createTx = document.getElementById('create-tx') || document.getElementById('buy-nft')
createTx?.addEventListener('click', () => transaction(bladeSigner))


const disconnect = document.getElementById('disconnect')
disconnect?.addEventListener('click', disconnectWallets)


function disconnectWallets() {
    if (!confirm('Disconnect now?')) return
	// Clear localStorage
	clearLocalStorage()
    window.location.reload()
}

function clearLocalStorage() {
	// Clear localStorage
    window.localStorage.removeItem("hashconnectData")
    window.localStorage.removeItem("hashconnectSessionData")
    //alert('Local Storage Cleared')
}


// const txSign = document.getElementById('txSign');
// txSign?.addEventListener('click', tx_sign);

//example reduce to 1 line

// add listener for button txSign (on /future page)
document.getElementById('txSign')?.addEventListener('click', () => tx_sign(bladeSigner))


