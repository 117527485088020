import {BladeSigner, HederaNetwork} from '@bladelabs/blade-web3.js';
import { AccountId } from '@hashgraph/sdk';
import { Transaction } from '@hashgraph/sdk';

// BladeSigner.

export default class {
    /** @type {BladeSigner} bladeSigner */
    bladeSigner = null
    params = null
    /** @type {AccountId} accountId */
    accountId = null

    constructor(network) {
        this.bladeSigner = new BladeSigner
        this.params = {
            network: network === 'testnet' 
                ? HederaNetwork.Testnet
                : HederaNetwork.Mainnet,
            dAppCode: "GoMint"
        }
    }

    async pairBlade() {
        await this.bladeSigner.createSession(this.params)
        this.setActiveAccountId()
        return this.accountId.toString()
    }

    async signTx(bytes) {
        const tx = Transaction.fromBytes(bytes)
        await this.bladeSigner.call(tx)
    }

    async signAndReturnBytes(tx) {
        const signedTx = await this.bladeSigner.signTransaction(tx)
        return [signedTx.toBytes(), signedTx.transactionId.toString()]
    }

    getActiveAccountId() {
        if (!this.accountId) this.setActiveAccountId()
		// todo trap the following:
		if (!this.accountId) console.log('DF87231 failed to set Blade account ID')
        return this.accountId.toString()
    }

    setActiveAccountId() {
        this.accountId = this.bladeSigner.getAccountId()
    }

    async call(tx) {
        return await this.bladeSigner.call(tx)
    }
}